#new-account {
  .terms_agree {
    .legal_statement {
      border: 1px solid $color-grey;
      height: 100px;
      overflow-x: hidden;
      overflow-y: auto;
      @include swap_direction(padding, 5px);
      margin-bottom: 10px;
      font-size: 12px;
      #signin & span {
        font-size: 12px;
      }
    }
    .group_label {
      padding-bottom: 10px;
      font-size: 12px;
    }
    .radio_group {
      margin-bottom: 10px;
      span {
        label {
          @include swap_direction(padding, 0 5px);
          font-size: 12px;
        }
      }
    }
  }
  .marketing_notes {
    @include swap_direction(margin, 10px 0);
    p {
      #signin & {
        @include swap_direction(margin, 0);
      }
    }
  }
  .password {
    &.form-item {
      width: 100%;
      .q_password,
      .t_password {
        margin-bottom: 10px;
      }
    }
    .password-example {
      vertical-align: text-top;
    }
  }
  .form-item {
    label {
      #signin & {
        display: inline;
        @include swap_direction(padding, 0 5px);
      }
    }
    &.mobile_number_field,
    &.mobile_pin_field {
      width: 100%;
      label {
        #signin & {
          display: inline;
        }
      }
      .button {
        width: auto;
        margin-top: 10px;
      }
      select {
        display: inline;
        width: 30%;
        height: calc(4em - -2px);
        line-height: 38px;
      }
      .form_mobile_input {
        #signin & {
          font-size: 12px;
          input {
            display: inline;
            width: 72px;
          }
        }
      }
    }
    &.pc_email_promo,
    &.sms_promo,
    &.postal_promo {
      #signin & {
        width: 100%;
        font-size: 12px;
        padding-bottom: 15px;
      }
      .group_label {
        padding-bottom: 10px;
      }
      .radio_group {
        #signin & {
          @include swap_direction(margin, 10px 10px 10px 0);
        }
        input[type='radio'] {
          #signin & {
            width: auto;
          }
        }
        label {
          font-weight: normal;
        }
      }
    }
    &.age_restriction {
      float: none;
      width: auto;
      padding-right: 0;
      input[type='checkbox'] {
        #signin & {
          width: auto;
        }
      }
      label.label {
        font-weight: normal;
      }
    }
  }
  .signin_submit {
    .bypass_link {
      display: inline-block;
      margin-#{$ldirection}: 20px;
      margin-top: 7px;
    }
  }
  .birth_container {
    width: 100%;
    .about_you {
      font-size: 13px;
      font-weight: bold;
    }
    .form_label {
      padding-top: 10px;
    }
    .birthday_container {
      display: flex;
      padding-top: 10px;
      .birth_year_container,
      .birth_month_container,
      .birth_day_container {
        width: 30.5%;
      }
      .birth_month_container,
      .birth_day_container {
        margin-#{$ldirection}: 13px;
      }
    }
  }
}

.account_page {
  &#landing {
    .account_landing_grid {
      div.column {
        width: 310px;
        height: 520px;
      }
      #profile_address,
      #default_shipping_address {
        border-bottom: 0;
        ul {
          li {
            border-bottom: 1px dotted $color-dotted-line;
            line-height: 24px;
          }
        }
      }
    }
    #welcome-msg {
      margin-top: 29px;
    }
  }
  &#purchases {
    .maxqty-overlay {
      @include swap_direction(padding, 25px);
      h2 {
        border: 0 none;
        color: $color-dark-blue;
        font-size: 20px;
        font-weight: bold;
        @include swap_direction(padding, 0);
        @include swap_direction(margin, 0);
        text-transform: uppercase;
      }
      .cart-overlay {
        padding-top: 20px;
      }
    }
  }
  &#account_profile {
    #content {
      #registration-wrapper {
        h3 {
          @include page_header;
        }
        .content {
          @include page_content;
        }
      }
      fieldset {
        &.fs {
          @include swap_direction(margin, 0 0 0 25px);
          overflow: hidden;
          width: 650px;
          div {
            &.form_element {
              float: $ldirection;
              min-width: 150px;
              width: 300px;
              @include swap_direction(margin, 0);
              font-size: 12px;
              overflow: hidden;
              padding-bottom: 11px;
              input[type='text'],
              input[type='password'] {
                width: 260px;
                display: block;
                @include swap_direction(margin, 3px 0 0);
                font-size: 12px;
                color: $color-gray-dark;
              }
              select {
                @include swap_direction(margin, 6px 0 0);
                display: block;
                border: 1px solid $color-black;
                width: 206px;
                font-size: 12px;
                color: $color-gray-light;
                height: 38px;
                &.error {
                  border: 1px solid $color-red;
                }
              }
              &.mobile_number {
                width: 100%;
                float: none;
                span {
                  float: $ldirection;
                  padding-#{$rdirection}: 10px;
                }
                input {
                  width: auto;
                }
                .blue_button {
                  margin-top: 10px;
                  display: block;
                  float: $ldirection;
                }
              }
              &.mobile_verify {
                width: 100%;
                float: none;
                input {
                  float: $ldirection;
                }
                .blue_button {
                  margin-top: 10px;
                  display: block;
                  float: $ldirection;
                }
              }
            }
          }
          div.birth_day_container,
          div.birth_month_container,
          div.birth_year_container {
            width: 150px;
            margin-#{$rdirection}: 10px;
            select {
              width: 90%;
            }
          }
          &.email_promotions {
            .pc_email_promo_container {
              width: 100%;
            }
            input[type='radio'] {
              vertical-align: sub;
              margin-bottom: 0;
              display: inline;
              float: none;
            }
            .field_label {
              display: block;
              float: $ldirection;
              width: 50%;
            }
            span {
              padding-#{$ldirection}: 20px;
            }
          }
        }
      }
      .address-static {
        margin-#{$ldirection}: 25px;
      }
      .demographic-form-container {
        margin-#{$ldirection}: 25px;
        fieldset {
          &.fs {
            margin-#{$ldirection}: 0;
          }
        }
      }
      #age_container,
      #birthday_container {
        float: $ldirection;
        width: 550px;
        min-width: 500px;
      }
      #birthday_container {
        span {
          float: $ldirection;
          margin-#{$rdirection}: 20px;
          vertical-align: middle;
          width: 185px;
          font-size: 12px;
        }
        div.form_element {
          margin-top: 0;
          vertical-align: middle;
        }
      }
      .submit_btn {
        float: $rdirection;
      }
    }
  }
  &#membership_withdrawal {
    #content {
      margin-top: 20px;
      #registration-wrapper {
        h3 {
          @include page_header;
        }
        .member-details {
          @include swap_direction(margin, 0 0 0 25px);
          overflow: hidden;
          width: 650px;
        }
        .radio_group {
          input[type='checkbox'] {
            margin-#{$rdirection}: 10px;
          }
        }
        .reason-container {
          @include swap_direction(margin, 10px 0);
          textarea {
            font-size: 12px;
            @include swap_direction(padding, 10px);
          }
        }
      }
    }
  }
  &#membership_withdrawal_confirm {
    #content {
      margin-top: 20px;
      #registration-wrapper {
        h3 {
          @include page_header;
        }
        .member-details {
          @include swap_direction(margin, 0 0 0 25px);
          overflow: hidden;
          width: 650px;
        }
        #sidebar {
          display: block;
          p {
            margin-#{$ldirection}: 25px;
          }
        }
      }
    }
  }
  &#address_book {
    .column-container {
      @include swap_direction(padding, 0);
    }
    .address-book-info {
      .twocol-split {
        clear: both;
        @include swap_direction(padding, 10px);
        .col-address {
          float: $ldirection;
          width: 222px;
          min-height: 320px;
          border: 2px solid $color-grey;
          margin-#{$ldirection}: 10px;
          padding-bottom: 10px;
          margin-bottom: 10px;
        }
        .col_addres_order {
          margin-#{$ldirection}: 0;
        }
        .address {
          margin-#{$ldirection}: 20px;
          width: 190px;
          span {
            width: 190px;
            border-bottom: 1px dotted $color-dotted-line;
            margin-top: 2px;
            margin-bottom: 2px;
            display: block;
            height: 1px;
          }
          .default-address {
            margin-bottom: 5px;
          }
        }
        .controls {
          margin-top: 8px;
        }
      }
      h3 {
        border-bottom: 1px solid $color-grey;
        color: $color-gray-dark;
        font-size: 11px;
        font-weight: bold;
        line-height: normal;
        @include swap_direction(padding, 15px 0 15px 21px);
      }
    }
    .form-item-postal {
      width: 100%;
      .fieldset-note {
        float: $ldirection;
      }
      .input_postal_code {
        float: $ldirection;
        width: auto;
        margin-#{$rdirection}: 10px;
      }
    }
    .address_lookup_submit {
      @include swap_direction(padding, 5px 30px 5px 10px);
      display: block;
    }
    @include popup_phone_fields;
    @include address-lookup-container;
    @include address-result-container;
    @include address-search-container;
    @include address-hint-container;
    @include address-submit-button;
  }
  @include popup_phone_container;
  &#order_detail {
    #newmyaccountsubnav {
      .accountNavItem5 {
        background: none;
        a {
          background: transparent;
          color: $color-black;
          border-#{$rdirection}: 2px solid $color-grey;
        }
      }
      .accountNavItem4 {
        background: url('/media/images/global/downArrow_darkBlue.png') no-repeat scroll 50% 100%
          transparent;
        height: 40px;
        a {
          background: $color-dark-blue;
          color: $color-white;
          border-#{$rdirection}: 0;
        }
      }
    }
  }
  #product-container {
    .add-link {
      width: 50%;
    }
  }
}

@mixin order_detail {
  h2 {
    &.reciept-header {
      border-bottom: none;
    }
  }
  .order-detail {
    margin-top: 10px;
    overflow: hidden;
    &:first-child {
      border: 2px solid $color-grey;
    }
    h3 {
      border-bottom: none;
      @include swap_direction(margin, 0);
    }
    #purchase_instruction {
      border: none;
      @include swap_direction(padding, 0);
      @include swap_direction(margin, 10px 0 0 0);
      overflow: hidden;
      width: auto;
    }
    .detail_copy {
      div {
        float: $ldirection;
        width: 239px;
        text-align: center;
        label {
          color: $color-black;
          border-bottom: 1px solid $color-grey;
          display: block;
          font-size: 15px;
          font-weight: bold;
          height: 38px;
          line-height: 38px;
        }
        p {
          color: $color-dark-grey;
          border-bottom: 1px solid $color-grey;
          height: 60px;
          line-height: 60px;
          @include swap_direction(margin, 0);
        }
        &.order_cancel {
          img {
            vertical-align: middle;
          }
        }
      }
    }
    .trans_detail_container {
      border: none;
      margin-top: 10px;
      overflow: hidden;
    }
    .trans_detail_item {
      float: $ldirection;
      line-height: 16px;
      min-height: 210px;
      @include swap_direction(margin, 0);
      width: 300px;
      @include swap_direction(padding, 0 0 15px 0);
      word-wrap: break-word;
      &.shipping_address_container,
      &.payment_method_container {
        width: 300px;
        h4,
        div {
          padding-#{$ldirection}: 45px;
        }
      }
      &.delivery_instructions_container {
        h4,
        div {
          padding-#{$ldirection}: 45px;
        }
      }
      &.gift_container {
        float: $rdirection;
        width: 356px;
        .gift_message {
          margin-right: 20px;
          word-break: break-all;
        }
      }
      &.payment_method_container {
        clear: $ldirection;
        width: 30%;
      }
      &.offer_container {
        width: 70%;
      }
      &.payment_method_container,
      &.offer_container {
        border-top: 1px solid #dbdbdb;
      }
      h4 {
        border-bottom: 1px solid $color-grey;
        color: $color-black;
        height: 38px;
        line-height: 38px;
        font-weight: bold;
        @include swap_direction(margin, 0 0 18px);
        @include swap_direction(padding, 0);
        text-transform: none;
      }
      .print_order_receipt {
        margin-top: 10px;
        display: block;
      }
    }
    #product-container {
      border: 2px solid $color-grey;
      width: auto;
      td.noborder {
        border: none;
        @include swap_direction(padding, 0);
      }
      td.delete {
        display: none;
      }
    }
    #order-totals {
      border-bottom: 2px solid $color-grey;
      border-left: 2px solid $color-grey;
      border-right: 2px solid $color-grey;
      border-top: none;
      overflow: hidden;
      width: auto;
      div.total_amount_details {
        @include swap_direction(padding, 15px 42px 10px 0);
        margin-top: 10px;
        overflow: hidden;
        border-top: 1px solid $color-grey;
      }
      td.right {
        padding-#{$rdirection}: 42px;
        &.last_col {
          padding-#{$rdirection}: 0px;
        }
      }
      .discount-cost {
        color: $color-red;
      }
    }
  }
}

#order_detail {
  @include order_detail;
}

#order_cancellation,
#order_cancellation_confirm {
  #content {
    border: 2px solid $color-grey;
    border-bottom: 1px solid $color-grey;
    overflow: hidden;
    width: 956px;
    @include swap_direction(margin, 10px auto);
    h3 {
      @include swap_direction(padding, 15px);
      font-size: 14px;
      font-weight: bold;
      border-bottom: 1px solid $color-grey;
    }
    .cancel-details {
      @include swap_direction(margin, 20px);
      #cancel-confirm {
        margin-bottom: 15px;
      }
    }
  }
}

#order_cancellation {
  #order-summary {
    width: 100%;
    th {
      border-bottom: 1px solid $color-grey;
      color: $color-black;
      height: 38px;
      text-align: center;
    }
    td {
      border-bottom: 1px solid $color-grey;
      @include swap_direction(padding, 18px 0);
      text-align: center;
      vertical-align: top;
    }
  }
  .form-submit {
    margin-top: 20px;
  }
}

#landing {
  .error_messages {
    width: 960px;
    margin-top: 10px;
  }
}

#password_request {
  .password_request {
    .form-submit {
      background: $color-blue-button url('/media/images/global/white_right_arrow.png') no-repeat
        scroll right center;
      border-width: 0;
      clear: left;
      color: white;
      cursor: pointer;
      font-size: 12px;
      font-weight: bold;
      height: 30px;
      padding: 0px 31px 0px 15px;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      width: auto;
    }
  }
}

.password_sent {
  &#content {
    border: 2px solid #bdbdbd;
    h2 {
      border-bottom: 1px solid #dbdbdb;
      color: #000000;
      font-size: 16px;
      font-weight: bold;
      margin: 0 0 30px;
      padding: 15px 0 15px 24px;
      text-transform: uppercase;
    }
    #pass-sent-info {
      margin: 0 29px 29px;
    }
    #password-sent {
      margin: 0 0 20px;
      width: 600px;
    }
    #email-address {
      margin-bottom: 25px;
    }
    div {
      &:last-child {
        margin-top: 25px;
      }
    }
    input,
    span {
      display: block;
    }
    .form-submit {
      background: $color-blue-button url('/media/images/global/white_right_arrow.png') no-repeat
        scroll right center;
      border-width: 0;
      clear: left;
      color: white;
      cursor: pointer;
      font-size: 12px;
      font-weight: bold;
      float: none;
      height: 30px;
      letter-spacing: 0;
      line-height: 0.7em;
      margin: 20px 0;
      padding: 7px 31px 7px 15px;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      width: auto;
    }
  }
}
#order_return {
  .reciept-header {
    border-bottom: none;
  }
  .order-return {
    margin-top: 10px;
    &.order-return_content {
      border: 2px solid $color-grey;
    }
    .detail_copy {
      div {
        float: $ldirection;
        width: 239px;
        text-align: center;
        label {
          color: $color-black;
          border-bottom: 1px solid $color-grey;
          display: block;
          font-size: 15px;
          font-weight: bold;
          height: 38px;
          line-height: 38px;
        }
        p {
          @include swap_direction(margin, 0);
          color: $color-dark-grey;
          border-bottom: 1px solid $color-grey;
          height: 60px;
          line-height: 60px;
        }
      }
    }
    #return-info-wrapper {
      p {
        padding: 15px 0 15px 45px;
        border-bottom: 1px solid $color-grey;
      }
    }
    #return-details {
      border-top: 1px solid $color-grey;
    }
    .trans_detail_container {
      border: none;
      margin-top: 10px;
      overflow: hidden;
    }
    .trans_detail_item {
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 0 0 15px 0);
      float: $ldirection;
      line-height: 16px;
      min-height: 210px;
      width: 300px;
      word-wrap: break-word;
      &.delivery_address_container,
      &.return_reason_container {
        width: 300px;
        h4,
        div {
          padding-#{$ldirection}: 45px;
        }
      }
      h4 {
        @include swap_direction(margin, 0 0 18px);
        @include swap_direction(padding, 0);
        color: $color-black;
        height: 38px;
        line-height: 38px;
        font-weight: bold;
        text-transform: none;
      }
    }
    #product-info {
      border: 2px solid $color-grey;
      width: auto;
      border-collapse: separate;
      td.noborder {
        @include swap_direction(padding, 0);
        border: none;
      }
      td.delete {
        display: none;
      }
      thead {
        th {
          padding: 9px 0 10px 0;
          text-transform: uppercase;
          text-align: center;
        }
      }
      td {
        border-top: 1px solid $color-grey;
        padding: 20px 0;
        vertical-align: top;
      }
      .thumb {
        padding: 10px 0;
        width: 200px;
        text-align: center;
      }
      .name {
        text-align: left;
        width: 503px !important;
      }
      .price {
        text-align: center;
        width: 115px;
      }
      .qty {
        width: 59px;
        text-align: center;
      }
      .total {
        text-align: center;
        width: 109px;
      }
    }
    #order-totals {
      border-bottom: 2px solid $color-grey;
      border-left: 2px solid $color-grey;
      border-right: 2px solid $color-grey;
      border-top: none;
      overflow: hidden;
      width: auto;
      float: none;
      padding: 10px 0 10px;
      text-align: right;
      table {
        width: 100%;
      }
      .total_amount_details {
        @include swap_direction(padding, 15px 42px 10px 0);
        margin-top: 10px;
        overflow: hidden;
        border-top: 1px solid $color-grey;
      }
      td.right {
        padding-#{$rdirection}: 42px;
        &.last_col {
          padding-#{$rdirection}: 0px;
        }
      }
      .discount-cost {
        color: $color-red;
      }
    }
  }
  .return-button {
    padding: 15px 0;
    float: #{$rdirection};
  }
}
#order_return_confirm {
  #content {
    @include swap_direction(margin, 10px auto);
    border: 2px solid $color-grey;
    h3 {
      @include swap_direction(padding, 15px);
      font-size: 14px;
      font-weight: bold;
      border-bottom: 1px solid $color-grey;
    }
    .order-detail-return {
      @include swap_direction(margin, 20px);
      #return-confirm {
        margin-bottom: 15px;
      }
    }
  }
}
