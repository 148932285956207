.checkout {
  #viewcart_banner {
    border: 0;
  }
  .sidebar-content-block {
    border: 2px solid $color-grey;
    margin-top: 10px;
    #other-policies {
      @include swap_direction(margin, 5px 20px 5px 20px);
    }
  }
  #wrapper {
    input[type='submit'] {
      &.blue_button {
        padding-top: 0;
      }
    }
  }
  .checkout-table {
    .cart-item-rows {
      .name,
      .delete {
        border-bottom: none;
        padding: 15px 0;
      }
      td {
        &:last-child {
          border-bottom: none;
        }
      }
    }
    .total {
      &__promo_message {
        @include swap_direction(padding, 15px 10px);
      }
    }
  }
  #works-well-with-wrapper {
    .recomendBox {
      float: $ldirection;
      @include swap_direction(margin, 0 10px 0 0);
      @include swap_direction(padding, 0);
      dl {
        border: 2px solid $color-grey;
        @include swap_direction(margin, 0 0 12px);
        @include swap_direction(padding, 10px);
        background: url('/images/products/spp_right_bg_gradient.jpg') repeat-x;
        width: 158px;
        min-height: 200px;
        display: inline-block;
      }
      dt.thumb {
        margin-bottom: 10px;
        img {
          position: static;
          border: 0;
        }
      }
      dd.name {
        color: $color-gray-light;
        font-size: 12px;
        text-decoration: none;
        line-height: 16px;
        @include swap_direction(margin, 10px 0);
        min-height: 92px;
        a {
          color: $color-gray-dark;
          font-weight: bold;
          text-decoration: none;
          width: 203px;
        }
        .www-prodname {
          font-weight: normal;
        }
        span {
          padding-#{$ldirection}: 10px;
        }
      }
      dd.add {
        float: $rdirection;
        @include swap_direction(margin, 12px 0 0 0);
        border: 0;
        padding-top: 8px;
        .blue_button {
          padding-top: 0;
        }
      }
    }
  }
  .shipping-popup-content,
  .return-popup-content,
  .max-popup-content {
    @include swap_direction(padding, 20px);
    h3 {
      font-weight: bold;
      font-size: 16px;
      border-bottom: 1px dotted $color-dotted-line;
      color: $color-black;
      margin-bottom: 7px;
      padding-bottom: 6px;
    }
  }
  #offer-code {
    input[type='submit'].offer_submit {
      padding-top: 0;
    }
  }
  .content-block {
    width: 100%;
  }
  #address_form_container {
    .address-form-content {
      input[type='text'] {
        font-size: 12px;
      }
    }
    .form-item {
      color: $color-gray-light;
      float: $ldirection;
      @include swap_direction(padding, 0 0 20px);
      width: 330px;
    }
    .form-item-postal {
      width: 100%;
      .fieldset-note {
        float: $ldirection;
        padding-top: 5px;
      }
      .input_postal_code {
        float: $ldirection;
        width: auto;
        margin-#{$rdirection}: 10px;
      }
    }
    #profile-postal {
      margin-bottom: 10px;
      position: relative;
    }
    @include address-lookup-container;
    @include address-result-container;
    @include address-search-container;
    @include address-hint-container;
    @include address-submit-button;
    h2 {
      border: 0 none;
      color: $color-dark-blue;
      font-size: 20px;
      font-weight: bold;
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 0);
      text-transform: uppercase;
    }
  }
  @include popup_phone_container;
  @include popup_phone_fields;
  #address_form_container {
    div.phone {
      width: 100%;
      select {
        height: calc(3em - -2px);
      }
    }
  }
  .deliver_instructions_text {
    width: 70%;
    input {
      width: 100%;
      font-size: 12px;
    }
  }
  .gift-options-wrapper {
    fieldset {
      max-width: 100%;
    }
    .gift-wrap-contain {
      float: $ldirection;
    }
    .gift-wrap-img {
      float: $ldirection;
    }
    .bool-item {
      float: none;
      width: 100%;
      padding-top: 10px;
    }
    .gift-message {
      #to,
      #from,
      #message {
        padding-bottom: 10px;
        label {
          width: 20%;
          float: $ldirection;
        }
        input,
        textarea {
          font-size: 12px;
        }
      }
    }
    .msg_counter {
      padding-#{$ldirection}: 20%;
    }
  }
  &#shipping {
    @include address-lookup-container;
    @include address-result-container;
    @include address-search-container;
    @include address-hint-container;
    @include address-submit-button;
    .content-block {
      .address-container {
        font-style: normal;
      }
    }
  }
  &#review {
    .review-checkout {
      margin-bottom: 10px;
    }
    #review-address-info {
      .trans_detail_container {
        table {
          dt {
            @include swap_direction(padding, 0px 0px 0px 50px);
            height: 43px;
            line-height: 43px;
            margin-bottom: 0;
            span {
              float: none;
              font-size: 16px;
              font-weight: bold;
            }
          }
          .order_delivery_content {
            .dotted-list-divider {
              @include swap_direction(padding, 30px 30px 15px 50px);
            }
            .gift-item-info {
              @include swap_direction(padding, 0px 30px 15px 50px);
            }
            .change_link {
              @include swap_direction(padding, 0px 0px 20px 50px);
            }
          }
          td {
            @include swap_direction(padding, 0);
          }
        }
        #delivery-address {
          width: 380px;
          .order_delivery_content {
            width: 380px;
          }
        }
        #delivery-options,
        #delivery-instructions {
          width: 300px;
          .order_delivery_content {
            width: 300px;
            #gift-info {
              .gift-item-info {
                li {
                  &:last-child {
                    word-break: break-all;
                  }
                }
              }
            }
          }
        }
      }
    }
    .checkout-table-wrap {
      margin-top: 10px;
    }
    .block-content {
      @include swap_direction(padding, 12px 0 11px 29px);
    }
    #payment-container {
      span.radio {
        padding-#{$rdirection}: 5px;
        font-size: 12px;
        input[type='radio'] {
          margin-#{$rdirection}: 2px;
        }
      }
      .escrow {
        font-size: 12px;
      }
    }
    ul {
      &#newmyaccountsubnav {
        margin-bottom: 20px;
      }
    }
  }
  &#confirm {
    #newsletter-info {
      display: none;
    }
    #print-order {
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .return-link-container {
      display: block;
    }
    span.radio {
      padding-#{$rdirection}: 20px;
    }
    .field_label {
      float: $ldirection;
      margin-#{$rdirection}: 10px;
      width: 25%;
    }
    #newsletter-container {
      .form-submit {
        padding-top: 0;
      }
    }
  }
  &#email_promo_thanks {
    #wrapper {
      margin-top: 20px;
    }
    .block-content {
      @include swap_direction(padding, 12px 0 11px 29px);
    }
    .email-promo-content {
      float: $ldirection;
      width: 79%;
    }
    .checkout-buttons {
      margin-top: 20px;
    }
    #customer-service {
      p {
        @include swap_direction(padding, 12px 0 11px 29px);
      }
    }
  }
  &#receipt {
    @include order_detail;
    .detail_copy {
      border-bottom: 1px solid $color-grey;
      @include swap_direction(padding, 10px 45px);
      .order_cancel {
        padding-top: 10px;
      }
    }
    .trans_detail_container {
      border: none;
    }
    .order-detail {
      margin-bottom: 20px;
      #product-container {
        width: 100%;
        .thumb {
          width: 200px;
        }
        .name {
          width: 503px;
          text-align: $ldirection;
        }
        .price {
          width: 115px;
          padding-#{$ldirection}: 0px;
        }
        .qty {
          width: 59px;
        }
        .total {
          width: 109px;
        }
        td {
          border-top: 1px solid $color-grey;
          @include swap_direction(padding, 20px 0);
          vertical-align: top;
          width: 154px;
          text-align: center;
        }
        th {
          text-align: center;
          @include swap_direction(padding, 9px 0 10px 0px);
        }
      }
      .trans_detail_container {
        .trans_detail_item {
          min-height: 220px;
          &.payment_method_container {
            clear: $ldirection;
            width: 30%;
          }
          &.offer_container {
            width: 70%;
          }
          &.payment_method_container,
          &.trans_detail_item.offer_container {
            border-top: 1px solid #dbdbdb;
          }
        }
      }
      #order-totals {
        @include swap_direction(padding, 10px 0 10px);
        .right {
          @include swap_direction(padding, 0 25px 4px 0);
          text-align: $rdirection;
        }
        table {
          width: 100%;
        }
      }
    }
  }
  #checkout_signin_new_user {
    padding: 11px 21px 20px 30px;
  }
  &#samples {
    .myaccountheader {
      font-size: 30px;
    }
    #no-thanks {
      display: none;
    }
    a.continue-link {
      padding: 0 11px;
    }
    #content {
      width: 960px;
      margin: 0 auto;
      #sample-controls {
        clear: both;
        text-align: right;
        margin-bottom: 25px;
        input.no-thanks-continue {
          background-color: $color-dark-blue;
          color: $white;
        }
      }
      #sample-products {
        .size {
          height: 17px;
        }
        .product-name,
        .sub-name,
        .sub-line {
          margin-bottom: 3px;
        }
        .product-name {
          font-weight: bold;
          min-height: 45px;
        }
        .product-desc {
          margin: 10px 0;
        }
      }
    }
  }
}

@include breakpoint($portrait-up) {
  .checkout {
    &#shipping,
    &#review,
    &#confirm {
      .experts_banner {
        .need-help-experts {
          margin-right: 6px;
          margin-top: -6px;
        }
      }
    }
  }
}
