#cs-container {
  color: blue !important;
  .contact-wrap {
    float: left;
    width: 700px;
    @include breakpoint($medium-down) {
      width: 100%;
      border: none;
    }
    border: 2px solid #dbdbdb;
    overflow: hidden;
    margin-top: 15px;
    h3 {
      width: 100%;
      border-bottom: 1px solid #dbdbdb;
      padding: 15px 0 15px 21px;
      color: #333;
      font: 14px '돋움', Verdana, Arial, sans-serif;
      font-weight: bold;
    }
    .contact-content {
      margin: 0 auto;
      padding: 36px 0 54px 36px;
      font: 12px '돋움', Verdana, Arial, sans-serif;
      color: #a4a4a4;
      @include breakpoint($medium-down) {
        padding: 36px 20px 14px 20px;
      }
    }
    .question th {
      line-height: 27px;
      text-align: left;
      font-size: 11px;
      width: 150px;
    }
    input {
      border: 1px solid #ccc;
      padding: 2px;
      font-size: 12px;
      height: 20px;
      line-height: 1em;
      &.error {
        border-color: $color-red;
      }
    }
    select {
      @include breakpoint($medium-down) {
        padding: 2px;
        height: 20px;
        line-height: 1em;
      }
    }
    textarea {
      font-size: 12px;
      @include breakpoint($medium-down) {
        width: 200px;
      }
    }
  }
  .contact-wrap1,
  .contact-wrap2 {
    float: left;
    width: 956px;
    @include breakpoint($medium-down) {
      width: 100%;
      border: none;
    }
    border: 2px solid #dbdbdb;
    overflow: hidden;
    margin-top: 15px;
    h3 {
      width: 100%;
      border-bottom: 1px solid #dbdbdb;
      padding: 15px 0 15px 21px;
      color: #333;
      font: 14px '돋움', Verdana, Arial, sans-serif;
      font-weight: bold;
    }
    .contact-content {
      padding: 26px 0 26px 26px;
      font: 11px '돋움', Verdana, Arial, sans-serif;
      color: #6a6a6a;
    }
    .use_product {
      padding-right: 95px;
    }
    .registered {
      padding-right: 110px;
    }
    .contact-content-info {
      padding: 36px 36px 0 36px;
      font: 11px '돋움', Verdana, Arial, sans-serif;
      color: #9a9a9a;
      line-height: 20px;
      @include breakpoint($medium-down) {
        padding: 36px 20px 0 20px;
      }
      p {
        line-height: 20px;
      }
      .nation-choice {
        width: 100%;
        padding: 20px 0;
        border-top: 1px solid #dbdbdb;
        font-size: 11px;
        line-height: 12px;
        font-weight: bold;
        text-align: center;
        @include breakpoint($medium-down) {
          text-align: right;
        }
      }
    }
  }
  .contact-wrap1 {
    @include breakpoint($medium-down) {
      display: none;
    }
  }
  .contact-wrap-right {
    @include breakpoint($medium-down) {
      display: none;
    }
    border: 2px solid #dbdbdb;
    margin-top: 15px;
    float: right;
    overflow: hidden;
    width: 216px;
    h3 {
      width: 100%;
      background: #f5f5f5;
      border-bottom: 1px solid #dbdbdb;
      padding: 15px 0 15px 0;
      color: #333;
      font: 14px '돋움', Verdana, Arial, sans-serif;
      font-weight: bold;
      text-align: center;
    }
    .qa-list {
      width: 186px;
      font-size: 11px;
      line-height: 14px;
      letter-spacing: -1px;
      color: #6a6a6a;
      margin: 16px 0 21px 16px;
      li {
        background: #fff url(../image/dot_04.gif) no-repeat 0 8px;
        padding: 6px 0 6px 10px;
        border-bottom: 1px dotted #a0a0a0;
        a {
          color: #6a6a6a;
          text-decoration: none;
        }
      }
    }
  }
  .ml30 {
    margin-left: 15px;
  }
  .ml30 {
    margin-left: 30px;
  }
  .mt10 {
    margin-top: 10px;
  }
  .mt35 {
    margin-top: 35px;
  }
  .ml50 {
    margin-left: 50px;
  }
}

.cs-page__content .customer_service_cs {
  height: 285px;
}

.cs-page__content-item {
  p {
    margin: 1em 0;
  }
  .cms_faq_tab {
    margin-top: 35px;
    height: 36px;
    width: 750px;
    background: url('/images/customer_service/qa_tabmenu.gif') no-repeat;
    li {
      display: inline-block;
      .faq_tab {
        display: block;
        text-indent: -99999px;
        height: 36px;
        width: 215px;
        cursor: pointer;
      }
    }
    .cms_tab_on .faq_tab {
      background: url('/images/customer_service/qa_tabmenu.gif') no-repeat;
      height: 36px;
      width: 219px;
      &.faq_tab_left {
        background-position: 0 -36px;
      }
      &.faq_tab_right {
        background-position: -219px -36px;
      }
    }
    .contactus_tab_off .faq_tab {
      background-position: -219px 0px;
      height: 36px;
      width: 215px;
      cursor: pointer;
    }
    ul li a {
      color: #666666;
      text-decoration: none;
    }
  }
  #category2 {
    display: none;
  }
  #category1,
  #category2 {
    margin-bottom: 20px;
  }
  .faq_service {
    position: relative;
    width: 750px;
    border: 2px solid #dbdbdb;
    overflow: hidden;
    margin-top: 10px;
    h3 {
      border-bottom: 1px solid #dbdbdb;
      padding: 15px 0 15px 21px;
      color: #000;
      font-size: 14px;
      font-weight: bold;
    }
    .faq_service_inner {
      width: 897px;
      margin: 0 auto;
      padding: 19px 0 20px 0;
      font-size: 12px;
    }
    li {
      background: url('/images/customer_service/dot_image.gif') no-repeat 4px 11px;
      line-height: 18px;
      color: #6a6a6a;
      padding: 7px 30px 8px 13px;
      font-weight: bold;
      list-style: none;
      &.displayN {
        display: none;
      }
      &.hd {
        width: 854px;
        background: #f5f5f5;
        font-weight: normal;
        line-height: 18px;
        padding: 10px 30px 6px 13px;
        margin-top: -1px;
      }
      a {
        color: #6a6a6a;
      }
    }
  }
}
