@include breakpoint($portrait-up) {
  .site-header__banner {
    &-offers {
      .basic-responsive p {
        line-height: 1.8;
      }
    }
  }
  .site-footer__column:nth-child(2) {
    padding-right: 20px;
  }
}

.mpp-filter-bar .mpp__filters__headline {
  padding: 0 20px;
}

.site-header__utility {
  line-height: $header-height;
}

.cs-page {
  .cs-page {
    &__content {
      &-item {
        padding: 20px;
        .faq_service {
          .faq_service_inner {
            width: 100%;
          }
          li {
            &.hd {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.hero-2-col {
  &:first-child {
    .hero-large__inner {
      &.hero-large__inner {
        &-height--200 {
          height: 320px;
        }
      }
    }
  }
}

.checkout {
  &#shipping,
  &#review,
  &#confirm {
    .myaccountheader {
      width: 270px;
    }
  }
}

.content-block-large {
  &__inner-hpos--left {
    &.content-block-large__inner {
      &-margin--50 {
        bottom: 3em;
        .content-block-large {
          &__text-wrapper {
            margin-left: 7px;
          }
        }
      }
    }
  }
}

.spp__container {
  [data-sku-base-id='69841'] + .spp_customer_reviews {
    display: none;
  }
  [data-sku-base-id='66900'] + .spp_customer_reviews {
    display: none;
  }
}

.js-basic-carousel {
  ul {
    &.slick-dots {
      z-index: -1;
    }
  }
}
