///
/// @file layout/_footer.scss
///
/// \brief Styles for site-wide page footers
///
///

$medium-down: max-width 959px;

.footer {
  .site-footer {
    &__main {
      margin: 35px 0 10px;
      @include breakpoint($medium-down) {
        margin-bottom: 0;
      }
    }
    &__column {
      &:first-child {
        border: none;
      }
      &:nth-child(2) {
        border-left: 1px solid #ccc;
      }
      @include breakpoint($medium-down) {
        &:nth-child(3) {
          display: block;
        }
        &:nth-child(2) {
          border: none;
        }
        &:last-child {
          border-top: 1px solid #ccc;
        }
      }
      .menu {
        ul {
          &.menu__list {
            height: auto;
          }
        }
      }
    }
  }
}
