.esearch-product__review {
  text-align: center;
}

.esearch-product__review-snippet {
  display: inline-block;
  margin: 0 auto;
  //margin: 0 auto .5em auto;
}

.esearch-product__cta__container {
  margin-top: 0.5em;
}
