$color-blue-button: #00427f;
$color-dotted-line: #a0a0a0;

@mixin blue_button {
  background: $color-blue-button url('/media/images/global/white_right_arrow.png') no-repeat scroll $rdirection center;
  border-width: 0;
  clear: $ldirection;
  color: $color-white;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  @include swap_direction(padding, 5px 31px 5px 15px);
  @include swap_direction(margin, 0);
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: auto;
}

@mixin grey_button {
  background: $color-dotted-line url('/media/images/global/white_right_arrow.png') no-repeat scroll $rdirection center;
  border-width: 0;
  clear: $ldirection;
  color: $color-white;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  @include swap_direction(padding, 5px 31px 5px 15px);
  @include swap_direction(margin, 0);
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: auto;
}

@mixin page_content {
  border: 2px solid $color-grey;
  margin-bottom: 20px;
}

@mixin page_header {
  border-bottom: 1px solid $color-grey;
  color: $color-black;
  font-size: 16px;
  font-weight: bold;
  @include swap_direction(margin, 0 0 10px);
  @include swap_direction(padding, 15px 0 15px 24px);
  text-transform: uppercase;
}

@mixin popup_phone_container {
  #address_form_container {
    .form-item {
      input[type='text'],
      input[type='tel'] {
        height: 3em;
      }
    }
    #address-form-popover {
      .fieldset-note {
        position: static;
        width: auto;
        margin-top: 6px;
      }
      select {
        height: calc(3em - -2px);
      }
    }
  }
}

@mixin popup_phone_fields {
  .phone {
    width: 100%;
    select,
    .number-convertor {
      width: 20%;
      float: $ldirection;
    }
    input {
      margin-#{$ldirection}: 10px;
    }
  }
}

@mixin address-lookup-container {
  #address-lookup-container {
    @include swap_direction(padding, 20px);
    input {
      font-size: 12px;
      display: block;
      margin-top: 10px;
    }
  }
}

@mixin address-result-container {
  .address-results-container {
    .rna-list {
      margin-bottom: 10px;
    }
    .sno {
      width: 30px;
      padding-bottom: 10px;
    }
    .full-address {
      width: 380px;
      padding-bottom: 10px;
      word-wrap: break-word;
    }
    .postal {
      width: 50px;
      padding-bottom: 10px;
    }
  }
}

@mixin address-search-container {
  .address-search {
    h4 {
      margin-top: 10px;
    }
    .town-submit-btn {
      @include swap_direction(margin, 10px 0);
    }
  }
}

@mixin address-hint-container {
  .address-hint-container {
    .hint-table {
      th {
        width: 150px;
        word-wrap: break-word;
      }
    }
    .building-name-registered {
      @include swap_direction(margin, 10px 0);
    }
  }
}

@mixin address-submit-button {
  .address-submit-button {
    .address-input-submit {
      @include swap_direction(padding, 5px 30px 5px 10px);
    }
  }
}

#wrapper {
  input[type='button'],
  input[type='submit'] {
    @include blue_button;
    &.blue_button {
      @include blue_button;
    }
  }
}

a {
  &.blue_button {
    @include blue_button;
  }
}

a {
  &.grey_button {
    @include grey_button;
  }
}
//Account Landing Breadcrumb Highlighting
.account_page {
  $breadcrumblink: (landing, account_profile, address_book, order_landing, purchases);
  @each $value in $breadcrumblink {
    $i: index($breadcrumblink, $value);
    &##{$value} {
      #newmyaccountsubnav {
        .subnavitem {
          background: none;
          a {
            background: transparent;
            color: $color-black;
            border-#{$rdirection}: 2px solid $color-grey;
          }
        }
        .accountNavItem#{$i} {
          background: url(/media/images/global/downArrow_darkBlue.png) no-repeat scroll 50% 100% transparent;
          height: 40px;
          a {
            background: $color-dark-blue;
            color: $color-white;
            border-#{$rdirection}: 0;
          }
        }
      }
    }
  }
}

.p-w-r {
  .tag-group {
    .pr-label-control {
      &.checked {
        span {
          width: 100%;
          display: block;
          overflow: hidden;
        }
      }
    }
  }
  #pr-war-form {
    .pr-pros-form-group {
      display: none;
    }
  }
}

.spp__container {
  #pr-reviewdisplay {
    .pr-helpful-no {
      .pr-helpful-count:before {
        content: '아니오-';
      }
    }
    .pr-helpful-yes {
      .pr-helpful-count:before {
        content: '예-';
      }
    }
    #pr-review-snapshot {
      .pr-snippet-stars-reco-reco {
        .pr-reco-to-friend-message {
          white-space: nowrap;
          margin-right: 22px;
        }
        .pr-reco-value {
          margin-left: 30px;
        }
      }
    }
  }
}

.review-preview {
  #war-ty-cons,
  #war-ty-best-uses {
    display: none;
  }
}

.search-wrapper {
  .esearch-product {
    &__short-desc {
      width: 100%;
    }
  }
}

.write_a_review__container {
  #pr-write {
    .pr-subscript {
      display: none;
    }
  }
}

.product-grid {
  &__item {
    .product-brief {
      &__info-container {
        .pr-category-snippet {
          &__total {
            display: block;
            margin-top: 6px;
          }
        }
      }
    }
  }
}

#new-account {
  .form-item {
    &.all_term_accept {
      width: 100%;
    }
  }
}

.section-gifts-and-sets {
  .qs-overlay-cbox {
    .product-quickshop {
      &__product {
        margin-left: 300px;
      }
    }
  }
}

.mpp-container {
  .product-brief {
    &__description {
      text-align: left;
    }
  }
}

.gnav-util {
  .cart-block {
    &__footer {
      &__button--checkout {
        color: #ffffff;
      }
    }
  }
}

.section-customer-service-terms-conditions {
  .cs-page {
    &__content {
      float: none;
      width: 100%;
    }
  }
}
