.elc-product-full,
.elc-sticky-container,
.elc-product-brief {
  .elc-product-cta-wrapper .elc-product-notify-me-button {
    background: $color-primary-300;
    border-color: $color-primary-300;
    color: black;
    pointer-events: none;
    &:hover {
      background: $color-primary-300;
      border-color: $color-primary-300;
      color: black;
      pointer-events: none;
    }
  }
}
