.change-password {
  .password-field {
    .password-field {
      &__info {
        top: 35%;
        &-checkout {
          #{$ldirection}: 80%;
          @include breakpoint($medium-up) {
            margin-#{$ldirection}: 0;
            width: 75%;
          }
        }
        &-reset {
          top: 80%;
          @include breakpoint($medium-up) {
            #{$ldirection}: 40%;
            width: 30%;
          }
        }
        &:before {
          @include breakpoint($medium-up) {
            #{$ldirection}: -5%;
            border: 6px solid transparent;
            border-#{$rdirection}-color: $color-gray;
            -ms-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
          }
        }
      }
    }
  }
  .password-update-overlay {
    .password-field {
      &__rules {
        @include breakpoint($medium-up) {
          font-size: 10px;
        }
      }
    }
    .profile-password-update {
      &__rules {
        li {
          display: flex;
        }
      }
    }
  }
  .profile-info {
    &__item {
      @include breakpoint($medium-up) {
        .profile-info__change_pwd_link {
          margin-top: 50px;
        }
      }
    }
  }
  .password-update-overlay {
    .profile-password-update {
      &__fieldset {
        @include breakpoint($medium-up) {
          margin: 35px 7px;
        }
        .form-item {
          input[type='password'] {
            height: 40px;
          }
        }
      }
    }
  }
}

.device-mobile {
  &.change-password {
    .password-field {
      &__rules {
        li {
          white-space: initial;
        }
      }
    }
  }
}
